<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row v-if="!loading && permission.read">
      <b-col>
        <b-card >
          <b-container>
            <b-row>
              <b-col sm="12" md="8">
                <p><b-link :to="{ name: 'Persons' }">all Persons</b-link></p>
                <h2>
                  <b-button class="mr-2" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'person' }}">Edit</b-button>
                  {{item.name}}
                </h2>
                <div class="mb-2">
                  {{item.persontype.name}}
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  name: 'Person',
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon: Person"
    this.$stat.log({ page: 'person', action: 'open person', model: 'person', model_id: this.$route.params.id })
    this.permission.read = ac.can(this.user.acgroups).readAny('person').granted
    this.permission.edit = ac.can(this.user.acgroups).updateAny('person').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      item: null,
      permission: {
        edit: false,
        read: false
      },
      savingStep: false
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        const apiName = 'cosmos'
        const path = `/person/${this.$route.params.id}`
        this.item = await this.$Amplify.API.get(apiName, path)
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
